import Vue from 'vue'
import App from './App.vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  { path: '/', component: () => import('./components/Home.vue') },
  { path: '/faq', component: () => import('./components/FAQ.vue') },

  // handle 404
  { path: '*', name: '404', component: () => import('./components/404.vue') }
];

const router =  new VueRouter({
  routes,
  mode: 'history'
});

Vue.config.productionTip = false

Vue.use(VueRouter);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
