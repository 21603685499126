<template>
<div class="page">
    <div class="main" :style="{paddingBottom: $route.path === '/' ? undefined : '0px'}" v-if="$route.name != '404'">
        <div class="header js-header" id="header">
            <div class="header__center center"><button class="header__burger js-header-burger"><span></span></button><a class="header__logo" href="/"><img class="header__pic" src="img/logo.png" alt="Logo"></a>
                <div class="header__wrap js-header-wrap">
                    <nav class="header__nav">
                        <a class="header__item" href="#">Chi siamo</a>

                        <a class="header__item" href="#servizi">Servizi</a>
                        <a class="header__item" href="#comelavoriamo">Come lavoriamo</a>
                        <a class="header__item contact-us-btn" href="#popup-contact">Contatti</a>
                        <a class="header__item" href="/faq">FAQ</a>

                    </nav>
                    <div class="header__photo"><img class="header__pic" src="img/menu-pic.png" alt="Monitor UI Editor "></div>
                </div>
                <!--<div class="header__search js-header-search"><button class="header__open js-header-open"><svg class="icon icon-search">
                            <use xlink:href="img/sprite.svg#icon-search"></use>
                        </svg></button>
                    <div class="header__field"><input class="header__input" type="text" placeholder="Search ..."></div>
                </div>--><a class="header__btn btn btn_pink contact-us-btn" href="#popup-controlpanel">Area Riservata</a>
            </div>
            <div class="header__bg js-header-bg"></div>
        </div>
        <div class="main__center center" v-if="$route.path === '/'">
            <div class="main__wrap">
                <h1 class="main__title title title_big" data-aos><span class="title__box"><span class="title__text">Dai </span></span><span class="title__box"><span class="title__text"><span class="title__line">Vita</span></span></span><span class="title__box"><span class="title__text">Alla tua</span></span><span class="title__box"><span class="title__text">Idea<span class="title__color">.</span></span></span></h1>
                <div class="main__info info" data-aos="animation-scale-y" data-aos-delay="400">La tua visione è la nostra ispirazione per creare soluzioni digitali straordinarie.</div>
                <div class="main__control" data-aos="animation-scale-y" data-aos-delay="550"><a class="main__btn btn btn_purple" href="#servizi">Scopri di più</a>
                    <!--<a class="main__link" href="/showcase">
                        <div class="main__icon"><img class="main__pic" src="img/play.svg" alt=""></div>
                        <div class="main__text">Scoprici in azione!</div> 
                    </a>-->
                </div>
                <div class="main__scroll" data-aos="animation-scale-y" data-aos-delay="650"><a class="main__link" href="#servizi">
                        <div class="main__icon"><img class="main__pic" src="img/scroll.svg" alt="Scroll "></div>
                        <div class="main__text">Scorri per esplorare</div>
                    </a></div>
            </div>
            <div class="main__view" data-aos>
                <div class="main__preview"><img class="main__pic js-parallax" data-scale="1.5" data-orientation="down" src="img/main-pic-1.png" alt="Play Video "></div>
                <div class="main__preview"><img class="main__pic js-parallax" data-scale="1.5" data-orientation="up" src="img/main-pic-2.png" alt="User Card "></div>
                <div class="main__preview"><img class="main__pic" src="img/mouse.png" alt="Mouse "></div>
                <div class="main__preview"><img class="main__pic" src="img/main-pic-3.png" alt="Monitor UI Editor Large "></div>
            </div>
            <div class="main__circles">
                <div class="main__circle"></div>
                <div class="main__circle js-rellax" data-rellax-speed="2"></div>
                <div class="main__circle js-rellax" data-rellax-speed="1"></div>
                <div class="main__circle js-rellax" data-rellax-speed="2"></div>
                <div class="main__circle js-rellax" data-rellax-speed=".3"></div>
                <div class="main__circle main__circle__wised"></div>
                <!--<div class="main__circle js-rellax" data-rellax-speed="1"></div>-->
            </div>
        </div>
    </div>

    <router-view></router-view>

    <div class="footer js-footer">
        <div class="footer__center center">
            <div class="footer__row">
                <div class="footer__col"><a class="footer__logo" href="/"><img class="footer__pic" src="img/logo.png" alt="Logo"></a></div>
                <div class="footer__col js-footer-col">
                    <div class="footer__category js-footer-category">La nostra Azienda<svg class="icon icon-arrow-down">
                            <use xlink:href="img/sprite.svg#icon-arrow-down"></use>
                        </svg></div>
                    <div class="footer__menu js-footer-menu"><a class="footer__link" href="/chi-siamo">Chi siamo</a><a class="footer__link" href="#comelavoriamo">Come lavoriamo</a><a class="footer__link" href="#recensioni">I nostri progetti</a><a class="footer__link" href="#servizi">Servizi</a></div>
                </div>
                <div class="footer__col js-footer-col">
                    <div class="footer__category js-footer-category">Supporto<svg class="icon icon-arrow-down">
                            <use xlink:href="img/sprite.svg#icon-arrow-down"></use>
                        </svg></div>
                    <div class="footer__menu js-footer-menu"><a class="footer__link contact-us-btn" href="#popup-contact">Contatti</a><a class="footer__link" style="cursor: pointer;" @click="supportTicket">Apri un ticket</a><a class="footer__link" href="/faq">Consulta le FAQ</a><a class="footer__link" href="/lavora-con-noi">Lavora con noi</a></div>
                </div>
                <div class="footer__col js-footer-col">
                    <div class="footer__category js-footer-category">Documenti<svg class="icon icon-arrow-down">
                            <use xlink:href="img/sprite.svg#icon-arrow-down"></use>
                        </svg></div>
                    <div class="footer__menu js-footer-menu"><a class="footer__link" href="/cookie-policy">Cookie Policy</a><a class="footer__link" href="/privacy-policy">Privacy Policy</a></div>
                </div>
            </div>
            <div class="footer__bottom">
                <div class="footer__copyright">© 2023 | Powered and secured by Wised S.r.l.<br/>Viale Giuseppe Azari n. 72, 28922 Verbania (VB)<br/>P.IVA: 02732850033 - <a class="footer__link" style="display:unset;" href="mailto:legal@pec.wised.it">legal@pec.wised.it</a></div><a class="footer__scroll js-link-scroll" href="#header">
                    <div class="footer__icon"><img class="footer__pic" src="img/scroll.svg" alt="Scroll "></div>
                    <div class="footer__text">Torna all'inizio</div>
                </a>
            </div>
        </div>
    </div>
    <div class="popup mfp-hide" id="popup-contact">
        <div class="popup__wrap">
            <div class="popup__head"><a class="popup__logo" href="/"><img class="popup__pic" src="img/logo.png" alt="Logo"></a></div>
            <div class="popup__body">
                <div class="popup__title title">Contattaci<span class="title__color">.</span></div>
                <div class="popup__info">Inserisci i tuoi dettagli di seguito</div>
                <form class="popup__form" @submit="contactUs">
                    <div class="popup__field field">
                        <div class="field__label">Nome</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/user.svg" alt="User "></div><input class="field__input" ref="c_name" type="text" name="name" placeholder="Name" required>
                        </div>
                    </div>
                    <div class="popup__field field">
                        <div class="field__label">Email</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/sending-mail.svg" alt="Sending email "></div><input class="field__input" ref="c_email" type="email" name="email" placeholder="Email" required>
                        </div>
                    </div>
                    <div class="popup__field field">
                        <div class="field__label">Telefono</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/phone.svg" alt="Phone "></div><input class="field__input" type="phone" ref="c_phone" name="phone" placeholder="Telefono">
                        </div>
                    </div>
                    <div class="popup__field field field_textarea">
                        <div class="field__label">Messaggio</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/message.svg" alt="Message "></div><textarea class="field__textarea" ref="c_message" name="Message" placeholder="Message" required></textarea>
                        </div>
                    </div><button class="popup__btn btn btn_purple" type="submit">Invia</button>
                </form>
                <div class="popup__circles">
                    <div class="popup__circle"></div>
                    <div class="popup__circle"></div>
                </div>
            </div>
            <div class="popup__bg">
                <div class="popup__preview"><img class="popup__pic" src="img/popup-pic-1.png" alt="Popup "></div>
                <div class="popup__preview"><img class="popup__pic" src="img/cursor-1.png" alt="Cursor "></div>
            </div>
        </div>
    </div>

    <div class="popup mfp-hide" id="popup-controlpanel">
        <div class="popup__wrap">
            <div class="popup__head"><a class="popup__logo" href="/"><img class="popup__pic" src="img/logo.png" alt="Logo"></a></div>
            <div class="popup__body">
                <div class="popup__title title">Accedi<span class="title__color">.</span></div>
                <div class="popup__info">Accedi al pannello di controllo.</div>
                <form class="popup__form" action="https://controlpanel.wised.it/login.html" method="post">
                    <div class="popup__field field">
                        <div class="field__label">Utente</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/user.svg" alt="User "></div><input class="field__input" type="text" name="uname" placeholder="Utente.." required>
                        </div>
                    </div>
                    <div class="popup__field field">
                        <div class="field__label">Password</div>
                        <div class="field__wrap">
                            <div class="field__icon"><img class="field__pic" src="img/password.svg" alt="Password "></div><input class="field__input" type="password" name="pass" placeholder="Password.." required>
                        </div>
                    </div><button class="popup__btn btn btn_purple" name="login" value="Entra" type="submit">Accedi</button>
                </form>
                <div class="popup__circles">
                    <div class="popup__circle"></div>
                    <div class="popup__circle"></div>
                </div>
            </div>
            <div class="popup__bg">
                <div class="popup__preview"><img class="popup__pic" src="img/popup-pic-1.png" alt="Popup "></div>
                <div class="popup__preview"><img class="popup__pic" src="img/cursor-1.png" alt="Cursor "></div>
            </div>
        </div>
    </div>

    <div class="popup mfp-hide" id="popup-subscribed">
        <div class="popup__wrap">
            <div class="popup__head"><a class="popup__logo" href="/"><img class="popup__pic" src="img/logo.png" alt="Logo"></a></div>
            <div class="popup__body">
                <div class="popup__title title">Congratulazioni<span class="title__color">.</span></div>
                <div class="popup__info">Iscrizione alla newsletter completata!</div>

                <div class="popup__circles">
                    <div class="popup__circle"></div>
                    <div class="popup__circle"></div>
                </div>
            </div>
            <div class="popup__bg">
                <div class="popup__preview"><img class="popup__pic" src="img/popup-pic-1.png" alt="Popup "></div>
                <div class="popup__preview"><img class="popup__pic" src="img/cursor-1.png" alt="Cursor "></div>
            </div>
        </div>
    </div>

    <div class="popup mfp-hide" id="popup-contacted">
        <div class="popup__wrap">
            <div class="popup__head"><a class="popup__logo" href="/"><img class="popup__pic" src="img/logo.png" alt="Logo"></a></div>
            <div class="popup__body">
                <div class="popup__title title">Grazie per averci contattati<span class="title__color">.</span></div>
                <div class="popup__info">Verrai ricontattato il prima possibile dal nostro staff!</div>

                <div class="popup__circles">
                    <div class="popup__circle"></div>
                    <div class="popup__circle"></div>
                </div>
            </div>
            <div class="popup__bg">
                <div class="popup__preview"><img class="popup__pic" src="img/popup-pic-1.png" alt="Popup "></div>
                <div class="popup__preview"><img class="popup__pic" src="img/cursor-1.png" alt="Cursor "></div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'App',
    components: {

    },

    mounted() {

        window.$('.contact-us-btn').magnificPopup({
            mainClass: 'mfp-with-zoom', // this class is for CSS animation below

            zoom: {
                enabled: true, // By default it's false, so don't forget to enable it

                duration: 300, // duration of the effect, in milliseconds
                easing: 'ease-in-out', // CSS transition easing function

                // The "opener" function should return the element from which popup will be zoomed in
                // and to which popup will be scaled down
                // By defailt it looks for an image tag:
                opener: function (openerElement) {
                    // openerElement is the element on which popup was initialized, in this case its <a> tag
                    // you don't need to add "opener" option if this code matches your needs, it's defailt one.
                    return openerElement.is('img') ? openerElement : openerElement.find('img');
                }
            }
        });

        // if query params contains subscribed=true
        // open the popup
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('subscribed') === '1') {
            window.$.magnificPopup.open({
                items: {
                    src: '#popup-subscribed',
                },
                type: 'inline'
            });
        }

    },

    methods: {
        slideToDev() {
            setTimeout(() => {
                if (document.querySelector('#sviluppo .slick-current').id == 'slick-slide20') {
                    document.querySelector('#sviluppo .js-package-next').click()
                }
            }, 1500)
        },
        supportTicket() {
            // mailto
            window.location.href = 'mailto:support@wised.it';
        },
        contactUs(e) {
            if (e.preventDefault) e.preventDefault();
            // Establishing the connection to the form.
            document.querySelector('.feedback-popup-button').click()
            window.YTFeedbackForm.getClientJSApi('cf0d2252-2419-4886-b5db-dfa261762132').then(async form => {

                // Setting the email value to jane.doe@example.com.
                form.setBlockValue('email', this.$refs.c_email.value);

                // Setting the summary.
                form.setBlockValue('summary', '[Contact Us] ' + this.$refs.c_name.value);

                // Setting the description.
                form.setBlockValue('description', this.$refs.c_message.value);

                form.setBlockValue('phone', this.$refs.c_phone.value);

                form.setBlockValue('customer', this.$refs.c_name.value);

                window.$.magnificPopup.open({
                    items: {
                        src: '#popup-contacted',
                    },
                    type: 'inline'
                });

                setTimeout(() => {

                    document.querySelector('.primary_ddae').click()
                }, 2000);

            });
        }
    }
}
</script>

<style>
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    /* ideally, transition speed should match zoom duration */
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-wrap~* {
    filter: blur(10px);
}

.feedback-popup-button,
.light_f331 {
    display: none !important;
}
</style>
